<template>
  <div class="card">
    <div class="card-header pb-0">
      <h1>Search Your Contacts</h1>
    </div>
    <div class="card-content pb-1">
      <div class="card-body">
        <div class="row pb-2">
          <div
              class="col-xs-12 col-sm-12"
              :class="{'col-lg-9': isCompanyModule($route.params),'col-lg-6': !isCompanyModule($route.params)}"
          >
            <div class="mb-xs-3">
              <SearchWithCollapse
                  :suggest="shouldSuggest"
                  :suggestData="contacts"
                  :onRecordClicked="searchRecordClicked"
                  :onEntered="searched"
                  :onSearch="searching"
              ></SearchWithCollapse>
            </div>
          </div>
          <div
              class="col-xs-12 col-sm-12 d-sm-flex"
              :class="{'col-lg-3': isCompanyModule($route.params),'col-lg-6': !isCompanyModule($route.params)}"
          >
            <router-link
                :to="addContactRoute"
                class="d-flex justify-content-center btn btn-primary btn-lg rounded-0 mt-1 mt-lg-0"
            >
              <div class="d-flex justify-content-center align-items-center">
                <span style="margin-left: 3px">Add Contact</span>
              </div>
            </router-link>

            <router-link
                :to="{name: 'contact.filter'}"
                class="ms-sm-1 d-flex justify-content-center btn btn-secondary btn-lg rounded-0 mt-1 mt-lg-0"
                v-if="!isCompanyModule($route.params)"
            >
              <div class="d-flex justify-content-center align-items-center">
                <span style="margin-left: 3px">Advance Search</span>
              </div>
            </router-link>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchWithCollapse from "@/components/atom/Contact/SearchWithCollapse";
import {inject} from "vue";
import handleContact, {handleHelpers} from "@/services/modules/contact";
import {has} from "lodash";

export default {
  name: "UserContactSearch",
  components: {
    SearchWithCollapse: SearchWithCollapse
  },
  props:{
    onShowContacts: {
      required: true
    },
    onReset: {
      required: true
    },
  },
  data: () => ({
    loadingData: false,
    searchable:{
      full_name: '',
      phone: '',
    },
    search:{
      searching: false,
      searchContacts: [],
      suggest: false,
      isEntered: false,
    },
    contactRes: [],
    contacts:[],
  }),
  watch:{
    loading(val) {
      if(!val && this.search.isEntered) this.$emit('showContacts', this.contactRes)
    },
    'search.isEntered'(val){
      if(val && !this.loading) this.$emit('showContacts', this.contactRes)
    },
  },

  computed:{
    shouldSuggest(){
      if(this.contacts.length < 1) return false;
      return this.search.searching && this.search.suggest
    },

    addContactRoute(){
      if(has(this.$route.params, 'companyId')){
        return {
          name: 'contacts-create',
          params: {
            companyId: this.$route.params.companyId,
            moduleId: this.$route.params.moduleId,
            menuId: this.$route.params.menuId,
            pageId: this.$route.params.pageId,
          }
        }
      }
      return {
        name: 'contact.profile.store'
      }
    },
  },
  methods: {
    searchRecordClicked(contactId){
      this.search.suggest = false;
      if(!!contactId){
        if(this.isCompanyModule(this.$route.params)) {
          this.$router.push({
            name: 'company.contacts.details',
            params:{
              companyId: this.$route.params.companyId,
              moduleId: this.$route.params.moduleId,
              menuId: this.$route.params.menuId,
              contactId: contactId
            }
          })
          return true
        }

        this.$router.push({name: 'contact.profile.details', params:{contactId: contactId}})
        return true
      }
    },
    searched(search){
      this.search.searching = true;
      this.search.suggest = false;
      this.search.isEntered = true;

    },
    searching(search){
      this.search.searching = true;
      this.search.suggest = true;
      this.searchable.full_name = search
      this.searchable.phone = search
      this.search.isEntered = false;
      this.contacts = []
      this.contactRes = []
      this.searchContacts();
    },
    getQuery(){
      let item, query = '';
      for (item in this.searchable)
      {
        if(!!this.searchable[item])
        {
          query = query+query.includes('&')?`${query}&${item}=${this.searchable[item]}`:`${item}=${this.searchable[item]}`
        }
      }

      return query
    },

    async searchContacts(){
      let res;

      if(!this.searchable.full_name) {
        this.search.suggest = false
        this.$emit('reset')
        return;
      }
      let query = this.getQuery();
      if(!!!query) return;

      try {
        this.loading = true;

        if(this.isCompanyModule(this.$route.params)) res = await this.searchCompanyOwnContacts(this.$route.params.companyId, query)
        else res = await this.searchUserOwnContacts(query)

        if (!res.status) {
          this.search.suggest = false
          this.showError(res.message)
          this.contactRes = res
        }
        if (res.status) {
          this.showSuccess(res.message)
          this.contactRes = res
          this.formatSearchableContacts(res)
        }
      } catch (err) {
        this.search.searchContacts = []
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response.data.message) {
          return this.showError(err.response.data.message)
        }
        if (err.response.statusText) {
          return this.showError(err.response.statusText)
        }
      } finally {
        this.loading = false
      }
    },
    formatSearchableContacts(res){
      if(has(res, 'data.data')){
        return this.contacts = res.data.data.map((item) => {
          return {
            contact_profile_id: has(item, 'contact_profile.id')?item.contact_profile.id:null,
            id: item.contact_id,
            name: has(item, 'contact_profile.full_name')?item.contact_profile.full_name:null,
            phone: has(item, 'contact_profile.contact_profile_items.phone')?item.contact_profile.contact_profile_items.phone[0].value:null,
            email: has(item, 'contact_profile.contact_profile_items.email')?item.contact_profile.contact_profile_items.email[0].value:null,
            photo: item.photo,
          }
        })

      }

      return this.contacts = [];
    }
  },
  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      searchUserOwnContacts,
      searchCompanyOwnContacts,
      loading,
    } = handleContact()

    const {isCompanyModule, generateTargetCompanyRoute} = handleHelpers()

    return {
      searchUserOwnContacts,
      searchCompanyOwnContacts,

      isCompanyModule,
      generateTargetCompanyRoute,

      loading,
      showError,
      showSuccess
    }
  }
}
</script>

<style scoped>

</style>