<template>
  <Paginate
      v-if="isActive"
      :page-count="totalPages"
      :force-page="meta.current_page??1"
      :click-handler="pageChangeRequested"
      :prev-text="'Prev'"
      :next-text="'Next'"
  >
  </Paginate>
</template>

<script>
import Paginate from "vuejs-paginate-next";

export default {
  name: "PaginationLaravel",
  emits: ['pageChangeRequest'],
  components: {
    Paginate: Paginate
  },
  props: {
    meta: {
      required: true,
    },
    links: {
      required: true,
    },
},
  data: () => ({
    currentPage: 0,
  }),
  computed:{
    totalPages(){
      let perPage = this.meta.per_page
      let totalItems = this.meta.total
      if(totalItems < perPage) return 1;

      return Math.ceil(totalItems/perPage)
    },

    isActive(){
      return !!this.meta && !!this.links;
    }
  },
  methods:{
    pageChangeRequested (page) {
      this.$emit('pageChangeRequest', page)
    },
  },
}
</script>

<style scoped>

</style>
