<template>
  <div class="search-container position-relative p-0 m-0 position-relative">
    <div class="input-group input-group-merge">
      <input
          style="font-size: 18px"
          type="text"
          class="form-control rounded-0"
          placeholder="Search contact user..."
          v-model="search"
          v-on:keyup.enter="entered"
      />
    </div>
    <div class="search-collapsable-container" v-if="suggest">
      <div class="collapsable-content">
        <a v-for="rec in suggestData" @click="recordClicked(rec.id)">
          <div class="avatar">
            <img src="/app-assets/images/default_profile_photo.jpg" alt="" height="40" width="40">
          </div>
          <span class="profile-name">{{ rec.name }}</span>
          <span class="ms-1">{{ rec.phone }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "lodash";
export default {
  name: "SearchWithCollapse",
  props: {
    suggest: {
      type: Boolean,
      default: false,
    },
    suggestData: {
      type: Array,
      default: [],
    },
    onEntered: {
      required: true
    },
    onSearch: {
      required: true
    },
    onRecordClicked: {
      required: true
    }
  },
  data: () => ({
    search: '',
  }),
  watch:{
    search: debounce(function(search){
      this.$emit('search', search)
    }, 1000)
  },

  methods:{
    entered: debounce(function(){
      this.$emit('entered', this.search)
    }, 1100),
    recordClicked(id){
      this.$emit('recordClicked', id)
    }
  },
}
</script>

<style scoped>
.search-collapsable-container{
  position: absolute;
  left: 0;
  right: 0;
  height: 30vh;
  z-index: 9;
  overflow-y: auto !important;
  overflow-x: auto !important;
  background-color: white;
  border: 1px solid #ddd;
  border-top: 0;
  box-shadow: 0px 2px 5px 1px #ddd;

}
.search-collapsable-container .collapsable-content{
  margin: 0;
  padding-top: 3px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
}

.search-collapsable-container .collapsable-content a{
  margin: 0;
  padding: 3px 0;
  font-size: 15px;
  background-color: white;
  padding-left: 6px;
  color: inherit;
  font-family: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.search-collapsable-container .collapsable-content a:hover{
  background-color: #f5f5f5;
  cursor: pointer;
}

.search-collapsable-container .collapsable-content .avatar{
  margin-left: 3px;
  margin-right: 6px;
}
.search-collapsable-container .collapsable-content .profile-name{
  min-width: 130px;
}

</style>