import Network from '@/services/network'
import {ref} from 'vue'
import {countBy, find, forEach, has, isEmpty, map} from "lodash";
import store from "@/store";


export default function handleContact() {
    const network = new Network;
    const loading = ref(true)

    const createQuery = (searchable) => {
        let item, query = '';
        for (item in searchable) {
            if (!!searchable[item]) {
                query = query+query.includes('&') ? `${query}&${item}=${searchable[item]}` : `${item}=${searchable[item]}`
            }
        }

        return query
    }

    const getLoginUserProfile = () => {
        return network.api('get', `/contact/user-profile`);
    }

    const getManualUserContact = (page = '', group) => {
        let query = {
            page: page,
            group: group
        }
        query = createQuery(query)
        return network.api('get', `/contact/user-contact-manual${!!query?'?'+query:''}`);
    }

    const getUserAllContacts = (page = '', group) => {
        let query = {
            page: page,
            group: group
        }
        query = createQuery(query)

        return network.api('get', `/contact/user-contact${!!query?'?'+query:''}`);
    }

    const getCompanyAllContacts = (companyId, page = '', group = '') => {
        let query = {
            page: page,
            group: group
        }
        query = createQuery(query)
        // let query = page !== '' ? `?page=${page}` : ''
        return network.api('get', `/contact/${companyId}/company-contact${!!query?'?'+query:''}`);
    }

    const getContactDetails = (id) => {
        return network.api('get', `/contact/user-contact/${id}`);
    }

    const getCompanyContactDetails = (companyId, contactId) => {
        return network.api('get', `/contact/${companyId}/company-contact/${contactId}`);
    }

    const getUsersBySearch = (query) => {
        return network.api('get', `/contact/search/users?${query}`);
    }

    const searchUserOwnContacts = (query) => {
        return network.api('get', `/contact/search/users/my-contacts?${query}`);
    }

    const filterUserOwnContacts = (query) => {
        return network.api('get', `/contact/filter/users/my-contacts?${query}`);
    }

    const searchCompanyOwnContacts = (company, query) => {
        return network.api('get', `/contact/search/company/${company}/contacts?${query}`);
    }

    const storeContactProfile = (data) => {
        return network.api('post', '/contact/user-profile-manual', data);
    }

    const storeCompanyContactProfile = (companyId, data) => {
        return network.api('post', `/contact/company-profile-manual/${companyId}`, data);
    }

    const updateContactProfile = (profileId, form, isManual = 0) => {
        if(isManual == 1) return network.api('patch', `/contact/user-profile-manual/${profileId}`, form);
        return network.api('patch', `/contact/user-profile/${profileId}`, form);
    }

    const updateCompanyContactProfile = (companyId, profileId, form, isManual = 0) => {
        if(isManual == 1) return network.api('patch', `contact/company-profile-manual/${companyId}/profile/${profileId}`, form);
        return network.api('patch', `/contact/company-profile/${profileId}`, form);
    }

    const updateProfileItemVisibility = (data) => {
        return network.api('post', '/contact/user-profile/item-publish', data);
    }

    const updateContactProfilePhoto = (form) => {
        return network.api('post', `/contact/user-profile/photo`, form);
    }

    const updateManualContactProfilePhoto = (profileId, form) => {
        return network.api('post', `/contact/user-profile-manual/${profileId}/photo`, form);
    }

    const updateCompanyContactProfilePhoto = (companyId, form) => {
        return network.api('post', `/contact/company-profile/${companyId}/photo`, form);
    }

    const updateManualCompanyContactProfilePhoto = (companyId, profileId, form) => {
        return network.api('post', `/contact/company-manual-profile/${companyId}/photo/${profileId}`, form);
    }

    const updateCompanyContactGroup = (companyId, contactId, form) => {
        return network.api('patch', `/contact/${companyId}/company-contact/${contactId}`, form);
    }

    const updateUserContactGroup = (contactId, form) => {
        return network.api('patch', `/contact/user-contact/${contactId}`, form);
    }

    const addToUserContact = (data) => {
        return network.api('post', '/contact/user-contact', data);
    }

    const addToCompanyContact = (companyId, data) => {
        return network.api('post', `/contact/${companyId}/company-contact`, data);
    }

    const fetchContactProfiles = (query) => {
        return network.api('get', '/contact-profile-names' + query);
    }

    const fetchCompanyAllContacts = (query) => {
        return network.api('get', `/contact/list` + query);
    }

    const fetchContactDetails = (type, id, query) => {
        return network.api('get', `/contact/details/${type}/${id}` + query);
    }

    return {
        storeContactProfile,
        storeCompanyContactProfile,
        addToUserContact,
        addToCompanyContact,

        updateContactProfile,
        updateCompanyContactProfile,
        updateContactProfilePhoto,
        updateManualContactProfilePhoto,
        updateCompanyContactProfilePhoto,
        updateManualCompanyContactProfilePhoto,
        updateProfileItemVisibility,
        updateCompanyContactGroup,
        updateUserContactGroup,

        getLoginUserProfile,
        getManualUserContact,
        getContactDetails,
        getCompanyContactDetails,
        getUserAllContacts,
        getCompanyAllContacts,
        getUsersBySearch,
        fetchCompanyAllContacts,
        fetchContactDetails,

        searchUserOwnContacts,
        filterUserOwnContacts,
        searchCompanyOwnContacts,

        createQuery,
        fetchContactProfiles,
        loading
    }
}


let handleInitData = () => {
    let groupItems = [
        {
            key: 'education',
            heading: 'Education',
            items: [
                {
                    logo: '/app-assets/images/icons/graduation-cap.png',
                    show: false,
                    fields: [
                        {
                            key: 'institute',
                            title: 'Institute',
                            value: '',
                        },
                        {
                            key: 'institute_location',
                            title: 'Institute Location',
                            value: '',
                        }
                    ],
                }
            ],
        },

        {
            key: 'experience',
            heading: 'Experience',
            items: [
                {
                    logo: '/app-assets/images/icons/expert.png',
                    show: false,
                    fields: [
                        {
                            key: 'company',
                            title: 'Compnay',
                            value: '',
                        },
                        {
                            key: 'industry',
                            title: 'Industry',
                            value: '',
                        },
                        {
                            key: 'location',
                            title: 'Industry Location',
                            value: '',
                        }
                    ],
                }
            ],
        },

        {
            key: 'skill',
            heading: 'Skills',
            items: [
                {
                    logo: '/app-assets/images/icons/key-skills.png',
                    show: false,
                    fields: [
                        {
                            key: 'skill',
                            title: 'Skill',
                            value: '',
                        },
                        {
                            key: 'skill_details',
                            title: 'Skill Details',
                            value: '',
                        },
                    ],
                }
            ],
        },

        {
            key: 'achievement',
            heading: 'Achievements',
            items: [
                {
                    logo: '/app-assets/images/icons/trophy.png',
                    show: false,
                    fields: [
                        {
                            key: 'achievement',
                            title: 'Achievement',
                            value: '',
                        },
                        {
                            key: 'achievement_details',
                            title: 'Achievement Details',
                            value: '',
                        },
                    ],
                }
            ],
        },

        {
            key: 'extra_activities',
            heading: 'Extra Activity',
            items: [
                {
                    logo: '/app-assets/images/icons/daily-tasks.png',
                    show: false,
                    fields: [
                        {
                            key: 'extra_activities',
                            title: 'Extra Activity',
                            value: '',
                        },
                        {
                            key: 'extra_activities_details',
                            title: 'Extra Activity Details',
                            value: '',
                        },
                    ],
                }
            ],
        },

        {
            key: 'prof_ref',
            heading: 'Professional Reference',
            items: [
                {
                    logo: '/app-assets/images/icons/change.png',
                    show: false,
                    fields: [
                        {
                            key: 'prof_ref',
                            title: 'Professional Reference',
                            value: '',
                        },
                        {
                            key: 'prof_ref_details',
                            title: 'Professional Reference Details',
                            value: '',
                        },
                    ],
                }
            ],
        },

    ];

    return {
        groupItemsInitData: groupItems,
    }
}

let handleHelpers = () =>{
    const totalShowAbleItems = (mulItem) => {
        return countBy(mulItem.items, obj => !!obj.show)["true"]
    }

    const convGroupItemToSaveFormReq = (profile, groupItem) => {
        let groups = []
        if(totalShowAbleItems(groupItem) > 0){


            forEach(groupItem.items, item => {
                let formattedItem = {
                    field: groupItem.key,
                    value: '',
                    items: [],
                }

                let fields = item.fields;
                forEach(fields, (field, index) => {
                    if(index === 0){
                        formattedItem.value = field.value
                        if(has(field, 'id') && !!field.id) formattedItem['id'] = field.id

                    }else{
                        let groupItem = {
                            field: field.key,
                            value: field.value
                        }
                        if(has(field, 'id') && !!field.id) groupItem['id'] = field.id
                        formattedItem.items.push(groupItem)
                    }
                })
                groups.push(formattedItem)

            })

        }
        return {
            full_name : profile.full_name,
            profile_items: groups
        }
    }

    const formatProfileItems = (profileItems, groupItems, groupItemsInitData) => {
        let newGroupItems = {}
        forEach(profileItems, (items, key) => {
            let ref = find(groupItemsInitData, o => o.key === key)
            if(!!ref)
            {
                newGroupItems[key] = {
                    key: key,
                    heading: ref.heading,
                    items: getGroupItems(items, ref),
                }
            }

        })

        let newModGroupItems = []

        forEach(groupItems, (multiItem) => {
            if(has(newGroupItems, multiItem.key)){
                newModGroupItems.push(newGroupItems[multiItem.key])
            }else{
                newModGroupItems.push(multiItem)
            }
        })
        return newModGroupItems
    }

    const getGroupItems = (groupItems, ref) => {
        let newGroupItems = [];
        forEach(groupItems, (groupItem) => {
            let fields = createFieldsFromGroupItemChildren(groupItem.children)
            fields.unshift({
                key: groupItem.field,
                value: groupItem.value,
                id: groupItem.id,
                title: '',
                is_public: groupItem.is_public,
            })

            fields = setProperFieldTitle(fields, ref)

            let newGroupItem = {
                logo:ref.items[0].logo,
                show: true,
                fields: fields
            }
            newGroupItems.push(newGroupItem)
        })

        return newGroupItems;
    }

    const setProperFieldTitle = (fields, ref) => {
        let newFields = [];
        let refFields = ref.items[0].fields
        forEach(fields, item => {
            let refField = find(refFields, o => o.key === item.key)
            if(!!!refField){
                refField = refFields[0]
                item.key = refField.key
            }

            if(!!refField){
                newFields.push({
                    ...item,
                    title: refField.title
                })
            }
        })

        return newFields;
    }

    const createFieldsFromGroupItemChildren = (children) => {
        let fields = [];
        forEach(children, (field, key) => {
            fields.push({
                key: key,
                title: field[0].field,
                value: field[0].value,
                id: field[0].id
            });
        })

        return fields;
    }

    const getFieldsIdFromGroupItem = (groupItem) => {
        return map(groupItem.fields, 'id')
    }

    const removeItemsFromGroupItem = (groupItem, itemIndex) => {
        if(groupItem.items.length > 1){
            groupItem.items.splice(itemIndex, 1)
            return true;
        }

        const {groupItemsInitData} = handleInitData()

        groupItem.items[itemIndex] = find(groupItemsInitData, o => o.key === groupItem.key).items[0]
        return true;
    }

    const isProfileEditable = (profile) => {
        if(isEmpty(profile)) return false;

        if(profile.is_manual == 1) return true;

        let user = store.getters["user/getUser"]

        return profile.user_id == user.id;
    }

    const isPublishable = (profile) => {
        if(profile.is_manual == 1) return false;

        return isProfileEditable(profile);
    }

    const isCompanyModule = (params) =>{
        return has(params, 'companyId')
    }

    const generateTargetCompanyRoute = (route, targetParamName, targetParamId) =>{
        let params =  {
            companyId: route.params.companyId,
            moduleId: route.params.moduleId,
            menuId: route.params.menuId,
        }

        params[targetParamName] = targetParamId
        return params
    }

    return {
        totalShowAbleItems: totalShowAbleItems,
        convGroupItemToSaveFormReq: convGroupItemToSaveFormReq,
        formatProfileItemsByRef: formatProfileItems,
        getFieldsIdFromGroupItem: getFieldsIdFromGroupItem,
        removeItemsFromGroupItem: removeItemsFromGroupItem,
        isProfileEditable: isProfileEditable,
        isPublishable: isPublishable,
        isCompanyModule: isCompanyModule,
        generateTargetCompanyRoute: generateTargetCompanyRoute,
    }
}


export {handleInitData, handleHelpers}
